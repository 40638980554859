import { XIcon } from "./x.icon";
import {
    CapterraIcon, FacebookIcon, G2GroundIcon, InstagramIcon,
    LinkedinIcon, ProductHunt, RedditIcon, TikTokIcon,
    TrustPilotIcon, YoutubeIcon,
} from ".";
import { LuText } from "react-icons/lu";
import { IoVideocamOutline } from "react-icons/io5";
import { WhatsappIcon } from "./whatsapp.icon";
import { TelegramIcon } from "./telegram.icon";
import { SlackIcon } from "./slack.icon";
import { DiscordIcon } from "./discord.icon";
import { AmazonIcon } from "./amazon.icon";
import { AirbnbIcon } from "./airbnb.icon";
import SenjaLogo from "@assets/migrate/senja-logo.png";
import TestimonialToLogo from "@assets/migrate/testimonial-logo.png";

export const MigrationSourceTypes = [
    {
        icon: (
            <div className="w-">
                <img
                    src={TestimonialToLogo}
                    className="w-5 h-5"
                />
            </div>
        ),
        name: "Testimonial.to",
        autoSink: false,
        value: "testimonial-to"
    },
    {
        icon: (
            <div className="">
                <img
                    src={SenjaLogo}
                    className="w-5 h-5"
                />
            </div>
        ),
        name: "Senja.io",
        autoSink: false,
        value: "senja"
    },
    // {
    //     icon: <GrStatusUnknown className="w-6 h-6" />,
    //     name: "Social Juice",
    //     autoSink: false,
    //     value: "socialJuice"
    // },
    // {
    //     icon: <GrStatusUnknown className="w-6 h-6" />,
    //     name: "Loox",
    //     autoSink: false,
    //     value: "loox",
    // }
];

export const TestimonialSourceTypes = [
    {
        icon: <LuText className="w-6 h-6" />,
        name: "Text Testimonial",
        autoSink: false,
        value: "text"
    },
    {
        icon: <IoVideocamOutline className="w-6 h-6" />,
        name: "Video Testimonial",
        autoSink: false,
        value: "video"
    },
    // {
    //     icon: <></>,
    //     name: "CSV / XLS",
    //     autoSink: false,
    //     value: "csv"
    // },
    {
        icon: <XIcon className="w-6 h-6" />,
        name: "Twitter X",
        autoSink: false,
        value: "X"
    },
    {
        icon: <LinkedinIcon className="w-6 h-6" />,
        name: "Linkedin",
        autoSink: false,
        value: "linkedin"
    },
    {
        icon: <FacebookIcon className="w-6 h-6" />,
        name: "Facebook",
        autoSink: false,
        value: "facebook"
    },
    {
        icon: <InstagramIcon className="w-6 h-6" />,
        name: "Instagram",
        autoSink: false,
        value: "instagram"
    },
    {
        icon: <TikTokIcon className="w-6 h-6" />,
        name: "Tiktok",
        autoSink: false,
        value: "tiktok"
    },
    {
        icon: <RedditIcon className="w-6 h-6" />,
        name: "Reddit",
        autoSink: false,
        value: "reddit"
    },
    {
        icon: <ProductHunt className="w-6 h-6" />,
        name: "Product Hunt",
        autoSink: false,
        value: "product-hunt"
    },
    // {
    //     icon: <GoogleIcon className="w-6 h-6" />,
    //     name: "Google",
    //     autoSink: true,
    //     value: "google"
    // },
    {
        icon: <CapterraIcon className="w-6 h-6" />,
        name: "Capterra",
        autoSink: true,
        value: "capterra"
    },
    {
        icon: <TrustPilotIcon className="w-6 h-6" />,
        name: "Trust Pilot",
        autoSink: true,
        value: "trustpilot"
    },
    {
        icon: <G2GroundIcon className="w-6 h-6" />,
        name: "G2",
        autoSink: true,
        value: "g2"
    },
    {
        icon: <AmazonIcon className="w-6 h-6" />,
        name: "amazon",
        autoSink: true,
        value: "amazon"
    },
    {
        icon: <AirbnbIcon className="w-6 h-6" />,
        name: "Airbnb",
        autoSink: false,
        value: "airbnb"
    },
    // {
    //     icon: <YelpIcon className="w-6 h-6" />,
    //     name: "Yelp",
    //     autoSink: true,
    //     value: "yelp"
    // },
    {
        icon: <YoutubeIcon className="w-6 h-6" />,
        name: "Youtube",
        autoSink: false,
        value: "youtube"
    },
    // {
    //     icon: <ShopifyIcon className="w-6 h-6" />,
    //     name: "Shopify",
    //     autoSink: false,
    //     value: "shopify"
    // },
    // {
    //     icon: <PlayStoreIcon className="w-6 h-6" />,
    //     name: "Play Store",
    //     autoSink: false,
    //     value: "play-store"
    // },
    // {
    //     icon: <AppStoreIcon className="w-6 h-6" />,
    //     name: "App Store",
    //     autoSink: false,
    //     value: "app-store"
    // },
    // {
    //     icon: <ApplePodcast className="w-6 h-6" />,
    //     name: "Apple Podcasts",
    //     autoSink: false,
    //     value: "apple-podcasts"
    // },
    {
        icon: <WhatsappIcon className="w-6 h-6" />,
        name: "WhatsApp",
        autoSink: false,
        value: "whatsapp"
    },
    {
        icon: <TelegramIcon className="w-6 h-6" />,
        name: "Telegram",
        autoSink: false,
        value: "telegram"
    },
    {
        icon: <SlackIcon className="w-6 h-6" />,
        name: "Slack",
        autoSink: false,
        value: "slack"
    },
    {
        icon: <DiscordIcon className="w-6 h-6" />,
        name: "Discord",
        autoSink: false,
        value: "discord"
    },
];

type SourceLogoProps = {
    source: Api.Testimonials.Type['source'];
};

export function RenderSourceLogo({ source }: SourceLogoProps) {
    return (
        <div title={source}>

            {source === "x" ? <XIcon className="w-6 h-6" /> : null}

            {source === "instagram" ? <InstagramIcon className="w-6 h-6" /> : null}

            {source === "facebook" ? <FacebookIcon className="w-6 h-6" /> : null}

            {source === "linkedin" ? <LinkedinIcon className="w-6 h-6" /> : null}

            {source === "tiktok" ? <TikTokIcon className="w-6 h-6" /> : null}

            {source === "reddit" ? <RedditIcon className="w-6 h-6" /> : null}

            {source === "productHunt" ? <ProductHunt className="w-6 h-6" /> : null}

            {source === "capterra" ? <CapterraIcon className="w-6 h-6" /> : null}

            {source === "trustPilot" ? <TrustPilotIcon className="w-6 h-6" /> : null}

            {source === "g2" ? <G2GroundIcon className="w-6 h-6" /> : null}

            {source === "amazon" ? <AmazonIcon className="w-6 h-6" /> : null}

            {source === "airbnb" ? <AirbnbIcon className="w-6 h-6" /> : null}

            {source === "youtube" ? <YoutubeIcon className="w-6 h-6" /> : null}

            {source === "whatsapp" ? <WhatsappIcon className="w-6 h-6" /> : null}

            {source === "telegram" ? <TelegramIcon className="w-6 h-6" /> : null}

            {source === "slack" ? <SlackIcon className="w-6 h-6" /> : null}

            {source === "discord" ? <DiscordIcon className="w-6 h-6" /> : null}

        </div>
    );

}