import { Button } from "@components/button";
import { Input } from "@components/input";
import { useAuthApiClient, } from "@hooks";
import { LocalStorageUtil } from "@utils/localStorage";
import { REGX } from "@utils/regx";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";


const schema = z.object({
    password: z.string()
        .min(8, { message: "Password must be atleast 8 characters" })
        .refine(password => new RegExp(REGX.lowerCase).test(password), { message: "must contain atleast one lowercase character" })
        .refine(password => new RegExp(REGX.upperCase).test(password), { message: "must contain atleast one uppercase character" })
        .refine(password => new RegExp(REGX.specialCharacters).test(password), { message: "must contain atleast one special character" })
});

export function ResetForgotPasswordPage() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const token = searchParams.get("token") ?? null;

    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const { resetForgotPassword } = useAuthApiClient({});

    const isAppsumoPasswordReset = searchParams?.get("app") === "appsumo" ?? null;

    useEffect(() => { if (!token) navigate("/login", { replace: true }); }, []);

    const onResetPassword = async () => {
        try {

            const passwordValidation = schema.safeParse({ password });

            if (!token) return;

            if (passwordValidation.success === false) setError(passwordValidation.error.message);

            await resetForgotPassword.mutateAsync({ password, token });

            if (isAppsumoPasswordReset) {

                LocalStorageUtil.set("onboardingCompleted", "false");

                LocalStorageUtil.set("auth", "true");

                navigate("/onboarding?screen=website", { replace: true });

                return;

            }

            navigate("/login", { replace: true });

        } catch (err) {

            if (err instanceof AxiosError) {
                toast.error(err.message);
            }
        }
    };

    return (
        <div className="w-[66%] h-full flex items-center justify-center">

            <div className="p-2 w-[40%]">

                <h4 className="my-5 text-xl font-bold mb-sm text-primary-bold">
                    Reset Your Password
                </h4>

                <div className="space-y-4">

                    <Input
                        type="password"
                        error={error}
                        value={password}
                        placeholder={"Enter your new password"}
                        onChange={(e) => setPassword(e.target.value)}
                    />


                    <Button
                        loading={resetForgotPassword.isPending}
                        disabled={resetForgotPassword.isPending ? true : false}
                        onClick={() => onResetPassword()}
                    >

                        {resetForgotPassword.isPending ? "loading..." : "Reset my password"}

                    </Button>

                </div>

                <p
                    className="py-2 mt-4 font-normal text-center text-sm"
                    onClick={() => navigate("/login", { replace: true })}
                >
                    Back to <u className="cursor-pointer text-primary"> Login </u>
                </p>

            </div>

        </div>
    );

}