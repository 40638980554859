import { Fragment } from "react";
import { CollectCard } from "./emptyTestimonials/collectCard";
import { ImportCard } from "./emptyTestimonials/importCard";
import { Stats } from "./stats";
import { cn } from "@utils/cn";
import { QueryKeys, useGetQueryData } from "@hooks";
import { SpacesHeader } from "./header";

export function DashboardPage() {

    const data = useGetQueryData<Api.SpacesClient.getDetails>([QueryKeys.spaces.getDetails]);

    const noTestimonials = (data?.analytics) ? false : true;

    return (
        <section className={cn(`
            w-full h-full overflow-y-auto scrollbar space-y-2 
            flex flex-col items-start 
        `, {
            "px-4": noTestimonials,
            "px-8 space-y-2": !noTestimonials
        })}>

            <SpacesHeader />

            {noTestimonials ?
                <Fragment>

                    <div className="w-full flex items-start justify-evenly h-[36%] py-2">

                        <CollectCard />

                        <ImportCard />

                    </div>

                    {/* <TestimonialsTrendChart /> */}

                </Fragment> :
                <Fragment>

                    <Stats />

                    {/* <TestimonialsTrendChart /> */}

                </Fragment>
            }

        </section>
    );
}