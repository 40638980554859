import { Rating } from "@components/rating";
import { useEffect, useRef, useState } from "react";
import { Text } from "@components/text";
import { useSearchParams } from "react-router-dom";
import { QueryKeys, useActiveSpace, useGetQueryData, useInvalidateQuery, useTestimonialsApiClient } from "@hooks";
import { Config } from "@config";
import { ParseAxiosError } from "@lib/axios";
import toast from "react-hot-toast";
import { CgTranscript } from "react-icons/cg";
import { SecondaryButton } from "@components/button";
import { MdOutlineFileDownload } from "react-icons/md";
import "./audio.style.css";
import { cn } from "@utils/cn";
import ReactHlsPlayer from "react-hls-player";

export function AudioTestimonial() {

    const [searchParams] = useSearchParams();

    const { formId } = useActiveSpace();

    const playerRef = useRef<any>(null);

    const testimonialId = searchParams.get("id");

    const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const { invalidate } = useInvalidateQuery();

    const [transcribed, setTranscribed] = useState(false);
    const [transcribedContent, setTranscribedContent] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [rating, setRating] = useState(0);
    const [rated, setRated] = useState(false);
    const [paused, setPaused] = useState(false);
    const [audioUrl, setAudioUrl] = useState("");

    const { downloadQuery } = useTestimonialsApiClient({
        downloadParams: {
            params: {
                enabled: false,
                refetchOnMount: false,
                retry: 0,
                onError: (err) => toast.error(ParseAxiosError(err).message),
                onSuccess: (data: Api.Testimonials.download) => onDownloadFileFromPresignedUrl(data.url)
            },
            apiParams: {
                id: testimonialId as string,
                formId
            }
        }
    });

    const { isFetching, isLoading } = downloadQuery;

    useEffect(() => {

        const item = testimonialsReq.testimonials.filter(item => item.id === testimonialId)[0];

        setTranscribed(item.testimonial.audio?.transcribed ?? false);
        setTranscribedContent(item.testimonial.audio?.transcribedText ?? "");

        setRated(item.testimonial.rating ? true : false);
        setRating(item.testimonial.rating ?? 0);

        if (playerRef.current && item.testimonial.audio) {

            playerRef.current.play();

            const url = Config.cdn.video() + item.testimonial.audio.url;

            setAudioUrl(url);

        }

    }, []);

    const onDownloadFileFromPresignedUrl = async (presignedUrl: string) => {
        try {

            setDownloading(true);

            const res = await fetch(presignedUrl);

            const blob = await res.blob();

            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');

            a.href = url;
            a.download = "testimonial.mp4";

            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);

            setDownloading(false);

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="relative">

            {rated ?
                <Rating
                    rated={rating}
                    readonly={true}
                /> : null
            }

            <div className={cn(`
                flex flex-col items-center justify-center border border-gray-300 
                rounded-lg p-3 mt-4 bg-gray-100
            `)}>

                <div className="boxContainer w-auto my-3 space-x-2">

                    <div className={cn("box", { "box1": paused === false })} />

                    <div className={cn("box", { "box2": paused === false })} />

                    <div className={cn("box", { "box3": paused === false })} />

                    <div className={cn("box", { "box4": paused === false })} />

                    <div className={cn("box", { "box5": paused === false })} />

                </div>

                <ReactHlsPlayer
                    playerRef={playerRef}
                    src={audioUrl}
                    autoPlay
                    controls
                    onPlay={() => setPaused(false)}
                    onPause={() => setPaused(true)}
                    className="w-full m-auto rounded-md outline-none border border-gray-200 shadow-sm hidden"
                />

            </div>

            <SecondaryButton
                disabled={(isFetching || isLoading || downloading)}
                loading={(isFetching || isLoading || downloading)}
                className="w-auto px-2 py-1 rounded-md absolute top-0 right-0 m-0"
                onClick={async (e) => {
                    e.preventDefault();
                    await invalidate([QueryKeys.testimonials.download]);
                    downloadQuery.refetch();
                }}
            >

                <MdOutlineFileDownload className="w-5 h-5" />

            </SecondaryButton>

            {/* {imageAttachments.length ?
                <div className="flex flex-wrap items-baseline ">

                    {imageAttachments.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            className="w-28 h-28 border rounded-md bg-gray-200 m-2"
                        />
                    ))}

                </div> : null
            } */}

            {transcribed ?
                <div className="my-4 space-y-1">

                    <Text variant="primary" size="md" className="font-medium">
                        Transcript
                    </Text>

                    <Text variant="primary" size="sm" className="font-normal text-gray-700">
                        {transcribedContent}
                    </Text>

                </div> :
                <div className="flex items-center justify-start space-x-2 text-gray-600 p-2 my-2">

                    <CgTranscript className="w-6 h-6" />

                    <p className="text-sm font-normal"> No transcript found </p>

                </div>
            }

        </div>
    );
}