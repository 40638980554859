import { ParseAxiosError } from "@lib/axios";
import { useMutation, } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { QueryKeys } from "./keys";

type Params = {
    // getProfileParams?: {
    //     params: Partial<CustomQueryParams>,
    // };
};

export function useUsersApiClient({ }: Params) {

    const uploadAsserts = useMutation({
        mutationKey: [QueryKeys.user.uploadAsserts],
        mutationFn: async (params: Api.UserApi.uploadAssertsParams) => {
            try {

                const formData = new FormData();

                formData.append("id", params.id);
                formData.append("type", params.type);
                formData.append("file", params.file);

                return (await axios.post<Api.UserApi.uploadAsserts>("/api/v1/user/assert", formData)).data;

            } catch (err: any) {
                console.log(err);
                throw ParseAxiosError(err);
            }
        },
        onError: (err: any) => toast.error(ParseAxiosError(err).message),
    });

    const saveOnBoarding = useMutation({
        mutationKey: [QueryKeys.user.onBoarding],
        mutationFn: async (params: Api.UserApi.onBoardingParams) => {
            try {
                return (await axios.post<Api.UserApi.onBoarding>("/api/v1/user/onboarding", params)).data;
            } catch (err) {
                throw err;
            }
        }
    });

    const getProfile = useMutation({
        mutationKey: [QueryKeys.user.getProfile],
        mutationFn: async () => {
            try {
                return (await axios.get<Api.UserApi.getUser>("/api/v1/user")).data;
            } catch (err) {
                throw err;
            }
        },
        // ...getProfileParams?.params
    });

    const getKey = useMutation({
        mutationKey: [QueryKeys.user.generateApiKey],
        mutationFn: async () => {
            try {

                return await axios.get<Api.UserApi.getKey>("/api/v1/user/extension/key");

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        uploadAsserts,
        getProfile,
        saveOnBoarding,
        getKey
    };

}