import { QueryKeys, useGetQueryData } from "@hooks";
import { BillingUtils } from "@utils/billingUtils";
import { FaStar } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { SecondaryButton } from "@components/button";

export function LtdCard() {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const plan = billing.plan;

    const onChangePlan = () => {
        window.open("https://appsumo.com/products/gozen-testimonials/?p=1", "_blank");
    };

    return (
        <div className="w-full h-[90%] fade-in">

            <div className="h-full p-5 flex items-start">

                <div className="inline-block relative w-[35%] px-6 py-6 rounded-2xl bg-primary space-y-3 shadow-lg">

                    <div className="absolute top-7 right-7 p-2 bg-gray-50/20 rounded-full">
                        <FaStar className="w-10 h-10 text-white" />
                    </div>

                    <div>

                        <div className="text-white text-sm font-medium mt-5">
                            AppSumo Life Time Deal.
                        </div>

                        <div className="text-white text-xl font-bold capitalize tracking-wide my-5">

                            {plan === "appsumo_tier_1" ? "Tier 1" : null}

                            {plan === "appsumo_tier_2" ? "Tier 2" : null}

                            {plan === "appsumo_tier_3" ? "Tier 3" : null}

                            {plan === "appsumo_tier_4" ? "Tier 4" : null}

                            {plan === "appsumo_tier_5" ? "Tier 5" : null}


                        </div>

                    </div>

                    <SecondaryButton
                        className="w-auto font-medium bg-white text-primary text-sm px-4 py-2 rounded-lg inline-block hover:bg-gray-200"
                        onClick={() => onChangePlan()}
                    >
                        Change Plan
                    </SecondaryButton>

                </div>

                <div className="w-[50%] mx-3">

                    <div
                        className={`
                            h-auto flex flex-col items-center justify-center mt-2 m-auto rounded-lg p-2 
                            mx-2 border shadow-xl w-[290px]
                        `}
                    >

                        <h3 className="text-center text-lg font-semibold capitalize mt-3">

                            {plan === "appsumo_tier_1" ? "Tier 1" : null}

                            {plan === "appsumo_tier_2" ? "Tier 2" : null}

                            {plan === "appsumo_tier_3" ? "Tier 3" : null}

                            {plan === "appsumo_tier_4" ? "Tier 4" : null}

                            {plan === "appsumo_tier_5" ? "Tier 5" : null}

                        </h3>

                        <div className="my-4 w-[90%] px-4">

                            {BillingUtils.get(plan as any)?.features.map((feature, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="flex gap-2 my-3"
                                    >

                                        <TiTick className="w-5 h-5 text-green-600" />

                                        <p className="text-sm font-normal"> {feature} </p>

                                    </div>
                                );
                            })}

                        </div>


                    </div>

                </div>

            </div>


        </div>
    );
}