import { Input } from "@components/input";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";
import { Fragment, useState } from "react";
import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { useImportTestimonialStore } from "@store";
import toast from "react-hot-toast";
import { z } from "zod";

const schema = z.object({
    name: z.string().min(1, "Name required."),
    content: z.string().min(1, "Testimonial cannot be empty.")
});

export function AmazonImport() {

    const { formId } = useActiveSpace();

    const { manualImport, amazonImporter } = useImportTestimonialApiClient();

    const [showManualImport, setShowManualImport] = useState(false);
    const [url, setUrl] = useState("");

    /*
        - TODO append to testimonials preview section 
    */

    const onManualImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: testimonial.name,
                content: testimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({
                        [key]: item.message
                    });

                });

            }

            await manualImport.mutateAsync({
                testimonial,
                formId,
                source: "amazon"
            });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    const onImport = async () => {
        try {

            if (!url) return;

            await amazonImporter.mutateAsync({ formId, url });

            setUrl("");

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <ImportCard
            isLoading={manualImport.isPending || amazonImporter.isPending}
            title="Import from Twitter"
            onImport={() => showManualImport ? onManualImport() : onImport()}
        >

            {showManualImport ?
                <ManuallyImport
                    fields={{
                        email: null,
                        company: false,
                        companyLogo: false,
                        role: false,
                        testimonial: {
                            text: { content: true, imageAttachments: true },
                            audio: { url: false, transcribedText: false },
                            video: { url: false, transcribedText: false }
                        },
                        sourceUrl: {
                            label: "Business Url",
                            placeholder: "https://www.amazon.in/Atomic-Habits-James-Clear/product-reviews/1847941834"
                        }
                    }}
                /> :
                <Fragment>

                    <Input
                        type="text"
                        label="Product Url"
                        placeholder="https://www.amazon.in/Atomic-Habits-James-Clear/product-reviews/1847941834"
                        required={true}
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />

                    <p
                        className="text-sm font-medium text-gray-600 underline mt-4 cursor-pointer"
                        onClick={() => setShowManualImport(true)}
                    >
                        Or Add a testimonial manually
                    </p>

                </Fragment>
            }

        </ImportCard>
    );
}