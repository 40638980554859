
export class Config {

    static AppEnv = import.meta.env.VITE_APP_ENV as "dev" | "beta" | "prod";

    static IsLocal = import.meta.env.VITE_LOCAL as "true" | "false";

    static STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY as string;

    static Api() {

        if (this.IsLocal) return "http://localhost:8080";

        if (this.AppEnv === "dev") return "http://localhost:8080";
        else if (this.AppEnv === "beta") return "https://api.dev.testimonial.gozen.io";
        else return "https://api.testimonial.gozen.io";

    }

    static Auth0() {

        const redirect = this.AppEnv === "dev" ? "http://localhost:3000/login/success" :
            this.AppEnv === "beta" ? "https://dev.app.testimonial.gozen.io/login/success" :
                "https://app.testimonial.gozen.io/login/success";

        const login = this.AppEnv === "dev" ? "http://localhost:3000/login" :
            this.AppEnv === "beta" ? "https://dev.app.testimonial.gozen.io/login" :
                "https://app.testimonial.gozen.io/login";

        const localRedirect = "http://localhost:3000/login/success";
        const localLogin = "http://localhost:3000/login";

        return {
            redirect: this.IsLocal ? localRedirect : redirect,
            login: this.IsLocal ? localLogin : login,
            clientId: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
            domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE as string
        };
    }

    static FrontEndUrl() {

        if (this.IsLocal) return "http://localhost:3000";

        if (this.AppEnv === "dev") return "http://localhost:3000";
        else if (this.AppEnv === "beta") return "https://app.dev.testimonial.gozen.io";
        else return "https://app.testimonial.gozen.io";

    }

    static EmbedScript(type: "form" | "testimonial" | "wallOfLove" | "richSnippet") {

        if (type === "form") {

            return this.AppEnv === "dev" || this.AppEnv === "beta" ?
                "https://assets.dev.testimonials.gozen.io/scripts/embed.js" :
                "https://assets.testimoinal.gozen.io/scripts/embed.js";

        } else if (type = "testimonial") {

            return this.AppEnv === "dev" || this.AppEnv === "beta" ?
                "https://assets.dev.testimoinals.gozen.io/scripts/testimonial.js" :
                "https://assets.testimoinal.gozen.io/scripts/testimonial.js";

        } else if (type === "wallOfLove") {

            return this.AppEnv === "dev" || this.AppEnv === "beta" ?
                "https://assets.dev.testimoinals.gozen.io/scripts/wol.js" :
                "https://assets.testimoinal.gozen.io/scripts/wol.js";


        } else if (type === "richSnippet") {

            return this.AppEnv === "dev" || this.AppEnv === "beta" ?
                "https://assets.dev.testimoinals.gozen.io/scripts/richSnippet.js" :
                "https://assets.testimoinal.gozen.io/scripts/richSnippet.js";

        } else new Error("invalid type.");

    }

    static PrivacyPolicyLink = "https://gozen.io/testimonial/privacy-policy/";

    static TermsAndConditionLink = "https://gozen.io/testimonial/terms-and-conditions/";

    static DocsLink = "https://docs.gozen.io/testimonials";

    static LandingPage = "https://gozen.io";

    static misc = {
        placeHolderImage: "https://asserts.dev.testimonial.gozen.io/misc/image-placeholder.png",
        placeHolderLogo: "https://asserts.dev.testimonial.gozen.io/misc/dummy-logo.png"
    };

    static ChromeExtensionLink = "https://chromewebstore.google.com/detail/senja-testimonial-extensi/lmldakdjelendidnmaieidggbffljlle";

    static ZapierAppLink = "https://zapier.com/apps/gozen-testimonials/integrations";


    static AllowedOrigin() {

        if (this.AppEnv === "dev") return "http://localhost:4000";
        else if (this.AppEnv === "beta") return "https://dev.testimonial.gozen.io";
        else return "https://testimonial.gozen.io";

    }

    static RenderEngineUrl() {

        if (this.AppEnv === "dev") return "http://localhost:4000";
        else if (this.AppEnv === "beta") return "https://dev.testimonial.gozen.io";
        else return "https://testimonial.gozen.io";

    }

    static cdn = {
        video: () => {

            if (this.AppEnv === "dev") return "https://video.dev.testimonial.gozen.io/";
            else if (this.AppEnv === "beta") return "https://video.beta.testimonial.gozen.io/";
            else return "https://video.testimonial.gozen.io/";

        },
        image: () => {

        }
    };

}