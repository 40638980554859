import { ErrorBadge } from "@components/badge";
import { Button } from "@components/button";
import { Input } from "@components/input";
import { Config } from "@config";
import { zodResolver } from "@hookform/resolvers/zod";
import { useBillingApiClient } from "@hooks";
import { cn } from "@utils/cn";
import { LocalStorageUtil } from "@utils/localStorage";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

const AppsumoSignupSchema = z.object({
    name: z.string().min(3).max(50),
    email: z.string().email(),
    acceptCondition: z.boolean(),
});

type AppsumoSignupSchemaType = z.infer<typeof AppsumoSignupSchema>;

export function AppSumoSignupPage() {

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [error, setError] = useState("");

    const { activateAppsumoPlan } = useBillingApiClient({});

    const { formState, register, setValue, handleSubmit, clearErrors } = useForm<AppsumoSignupSchemaType>({
        resolver: zodResolver(AppsumoSignupSchema),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            name: "",
            email: "",
            acceptCondition: true
        }
    });

    useEffect(() => {

        setTimeout(() => {

            const code = searchParams.get("code") ?? null;

            console.log(code);

            if (!code) {
                navigate("/login", { replace: true });
                return;
            }

        }, 500);

        const name = searchParams.get("name") ?? null;
        const email = searchParams.get("email") ?? null;

        if (name) setValue("name", name);

        if (email) setValue("email", email);

    }, []);

    const onSubmit: SubmitHandler<AppsumoSignupSchemaType> = async (data) => {
        try {

            const code = searchParams.get("code") ?? null;

            if (!code) return;

            if (!data.acceptCondition) {
                setError("Please accept our Terms of Service and Privacy Policy");
                return;
            }

            const result = await activateAppsumoPlan.mutateAsync({
                code: code as string,
                email: data.email,
                name: data.email,
            });

            console.log(">>|<<", { ...result });

            if (result.data.resetPassword) {

                navigate({
                    pathname: "/forgot-password/reset",
                    search: createSearchParams({
                        "token": result.data.resetPassword,
                        "email": data.email,
                        "app": "appsumo"
                    }).toString()
                }, {
                    replace: true
                });

                return;

            }

            LocalStorageUtil.set("onboardingCompleted", "false");
            LocalStorageUtil.set("auth", "true");

            navigate("/onboarding?screen=website", { replace: true });

            return;

        } catch (err: any) {
            console.log(22, err);
            setError(err.message);
        }
    };

    return (
        <div className={cn(`h-full max-h-full p-5 flex w-[60%] flex-col items-center justify-center`, {
            "w-full": window.innerWidth < 600
        })}>

            <div className={cn(`w-[55%] p-8 overflow-y-hidden bg-white/80 rounded-lg shadow-lg transition-all duration-200 ease-in-out`, {
                "w-full": window.innerWidth < 600
            })}>

                <h4 className="text-xl text-center font-semibold capitalize ">
                    Welcome Sumo-ling
                </h4>

                {error ?
                    <ErrorBadge
                        className='mt-2'
                        error={error}
                        onClose={() => setError("")}
                    /> : null
                }

                <form
                    className="w-full mt-4 space-y-4 transition-all duration-200 ease-in-out"
                    onSubmit={handleSubmit(onSubmit)}
                >

                    <Input
                        {...register("name")}
                        type='text'
                        error={formState.errors.name?.message}
                        required={true}
                        label='Name'
                        className='w-full'
                        onChange={() => clearErrors("name")}
                    />

                    <Input
                        {...register("email")}
                        error={formState.errors.email?.message}
                        required={true}
                        label='Email'
                        className='w-full'
                        onChange={() => clearErrors("email")}
                    />

                    <div className="px-1 my-2 space-y-2">

                        <div className="flex items-center space-x-2">

                            <input
                                {...register("acceptCondition")}
                                type="checkbox"
                                className='w-4 h-4'
                            />

                            <p className="text-xs font-normal">

                                I agree to the

                                <u
                                    className="cursor-pointer hover:text-primary mx-1"
                                    onClick={() => window.open(Config.TermsAndConditionLink, "_blank")}
                                >
                                    Terms of Service
                                </u>

                                and

                                <u
                                    className="cursor-pointer hover:text-primary mx-1"
                                    onClick={() => window.open(Config.PrivacyPolicyLink, "_blank")}
                                >
                                    Privacy Policy.
                                </u>
                            </p>

                        </div>

                    </div>

                    <Button
                        type='submit'
                        loading={activateAppsumoPlan.isPending}
                        disabled={activateAppsumoPlan.isPending}
                    >
                        Create Account
                    </Button>

                </form>

                <p className="mx-2 mt-5 text-sm text-center text-gray-600 cursor-pointer">
                    Already have an account?
                    <u
                        className="ml-1 text-blue-500"
                        onClick={() => navigate("/login", { replace: true })}
                    >
                        Login
                    </u>
                </p>

            </div>

        </div>
    );
}