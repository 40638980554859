import { useState } from "react";
import { z } from "zod";
import { MigrateCard } from "../card";
import TestimonialToLogo from "@assets/migrate/testimonial-logo.png";
import { Input } from "@components/input";
import { useActiveSpace, useMigrateApiClient } from "@hooks";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";


const schema = z.object({
    url: z.string().refine((val) => {
        try {

            const { host } = new URL(val);

            return host === "testimonial.to";

        } catch (err) {
            return false;
        }
    }, "Enter a valid testimoinal.to wall of love url.")
});

export function TestimonialScraper() {

    const { formId } = useActiveSpace();

    const [url, setUrl] = useState("");
    const [error, setError] = useState("");

    const { testimonialToImporter } = useMigrateApiClient();

    const navigate = useNavigate();

    const onMigrate = async () => {
        try {

            const result = schema.safeParse({ url });

            setError(result.success === false ?
                result.error.issues[0].message :
                ""
            );

            await testimonialToImporter.mutateAsync({
                url,
                formId,
            });

            toast.success("Successfully, migrated your wall of love.");

            navigate("/testimonials");

        } catch (err) {
            console.log(err);
        }
    };

    const onChange = (val: string) => {

        setUrl(val);

        if (!val && error) setError("");

        const result = schema.safeParse({ url });

        if (result.success) setError("");

    };


    return (
        <MigrateCard
            title="Migrate your Testimonial.to Wall of Love"
            icon={
                <div className="">
                    <img
                        src={TestimonialToLogo}
                        className="w-5 h-5"
                    />
                </div>
            }
            disable={url && !error ? true : false}
            onMigrate={() => onMigrate()}
            isLoading={testimonialToImporter.isPending}
        >

            <Input
                type="text"
                label="Wall of Love URL"
                placeholder="https://testimonial.io/1hh123kj/all"
                required={true}
                value={url}
                error={error}
                onChange={(e) => onChange(e.target.value)}
            />

        </MigrateCard>
    );
}